import FilialCard from "./FilialCard";
import berti3 from "../assets/images/berti3.jpg";
import berti1 from "../assets/images/berti1.jpg";
import berti2 from "../assets/images/berti2.jpg";
import berti4 from "../assets/images/berti4.png";
import berti5 from "../assets/images/berti5.png";
import fundo3 from "../assets/images/fundo4.png";

const lojas = [
	{
		cidade: "São José do Rio Pardo",
		funcao: "Matriz",
		texto: "Segunda a Sábado: das 07h às 22h",
		email: "comercial@bertihortifrutti.com.br",
		endereco:
			"São José do Rio Pardo - Est Mun Pref Aurino Villela de Andrade, S/N – Sítio São Joaquim",
		enderecoGPS: "https://maps.app.goo.gl/C7bgYafjcdiyNBbY7",
		imagem: berti4,
		whatsapp: "5519994943751",
	},
	{
		cidade: "Ribeirão Preto",
		funcao: "Filial",
		texto: "Segunda, Quarta, Quinta e Sexta-feira: das 05h às 12h",
		email: "comercialrbr@bertihortifrutti.com.br",
		endereco:
			"Av. Luiz Maggioni, nº 4005 - Dist Emp Pref Luiz Roberto Jabali, CEAGESP - Pavilhão G.C. Mód 146-147",
		enderecoGPS: "https://maps.app.goo.gl/sGUqZBtEywDo4kd49",
		imagem: berti5,
		whatsapp: "5519994151876",
	},
	{
		cidade: "São Paulo",
		funcao: "Filial",
		texto: "Segunda a Sexta-feira: das 06h às 17h",
		email: "comercialsp@bertihortifrutti.com.br",
		endereco:
			"São Paulo - Av. Dr. Gastão Vidigal, nº 1946 – Vila Leopoldina, CEAGESP Pav APA – Mód 10 – Box 06",
		enderecoGPS: "https://maps.app.goo.gl/3P8LMC8MFtzNuU7x7",
		imagem: berti2,
		whatsapp: "5511988874840",
	},
	{
		cidade: "São José do Rio Preto",
		funcao: "Filial",
		texto: "Segunda, Terça e Quarta-feira: das 04h às 12h",
		email: "comercialrpr@bertihortifrutti.com.br",
		endereco:
			"SJ do Rio Preto - Av. João Batista Vetorasso, nº 1600, CEAGESP – Pav G.C. Mód 106 a 109-115-116 Pav G.P.B. Box 09",
		enderecoGPS: "https://maps.app.goo.gl/RbWBAdopLNAUDki18",
		imagem: berti1,
		whatsapp: "5519992312038",
	},
	{
		cidade: "Campo Grande",
		funcao: "Filial",
		texto: "Segunda a Sexta-feira: das 04h às 14h",
		email: "comercialcg@bertihortifrutti.com.br",
		endereco:
			"Campo Grande - Rua Antônio Rahe, nº 680 - Mata do Jacinto, CEASA Bloco 05 Box 10",
		enderecoGPS: "https://maps.app.goo.gl/AoweJPPqtPuYCPuz7",
		imagem: berti3,
		whatsapp: "5567996278396",
	},
];

function Filiais() {
	return (
		<div
			id="locais"
			className="w-ful flex flex-col items-center sm:pb-30 md:pb-20 xl:pb-20 pb-28 gap-[6rem] md:gap-[4rem] mt-20 sm:mt-10 relative"
		>
			<h2 className="text-[3rem] font-bold md:text-[2.3rem]">Nossas filiais</h2>
			<div className="flex flex-col justify-center items-center gap-[4.3rem]">
				{lojas.map((loja, i) => (
					<FilialCard loja={loja} key={i} />
				))}
			</div>

			<img
				src={fundo3}
				alt="fundo1"
				className="absolute md:hidden sm:-right-[100px] top-[1000px] -right-[620px] z-[1] md:"
			/>
		</div>
	);
}

export default Filiais;
