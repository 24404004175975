import React from "react";
import bananaImg from "../assets/images/banana.png";
import alfaceImg from "../assets/images/alface.png";
import pimentaoImg from "../assets/images/pimentao.png";
import fundo2 from "../assets/images/fundo2.png";

const cards = [
	{
		title: "Cultivamos em fazenda sustentaiveis e de energia limpa",
		description:
			"Produzimos com respeito à natureza, usando práticas responsáveis e energia renovável, entregando frescor e qualidade. 🌱☀️",
		img: bananaImg,
		color1: "#FFFF43",
		color2: "#E8E848C4",
		margin: "-4.8rem",
	},
	{
		title: "Sabor que vem da terra com amor",
		description:
			"Nossos produtos são cultivados com dedicação, buscando sempre o melhor para você e sua família, com sabor autêntico e cheio de nutrientes. 🌱",
		img: alfaceImg,
		color1: "#70D460",
		color2: "#4EC23CC4",
		margin: "-4.2rem",
	},
	{
		title: "Alimento mais fresco para você e toda sua família",
		description:
			"Garantimos produtos frescos e de qualidade, colhidos no momento certo e entregues com carinho, para levar saúde e sabor à sua mesa. 🌿",
		img: pimentaoImg,
		color1: "#E26059",
		color2: "#C94A43C4",
		margin: "-5.6rem",
	},
];

const Cultura = () => {
	return (
		<div
			id="cultura"
			className="w-ful flex flex-col items-center py-16 gap-[9rem] mt-32 md:mt-8 md:gap-4 relative"
		>
			<h2 className="text-[3rem] font-bold text-center md:text-[2.3rem] xl:text-[4rem] md:mb-32">
				Nossa cultura de{" "}
				<span className="text-verde-escuro2">sustentabilidade</span>
			</h2>
			<div className="flex justify-between gap-[4.3rem] mt-8 xl:mt-0 md:flex-col xl:flex-col sm:px-[1rem] md:px-[2rem] xl:px-[10rem] xl:gap-[6rem] relative z-[2]">
				{cards.map((c, i) => (
					<div
						className="flex flex-col w-[33%] xl:w-full items-center relative scale-90"
						key={i}
					>
						<div
							className="absolute z-10 sm:-top-8 xl:top-[-7%]"
							style={{ marginTop: c.margin }}
						>
							<img src={c.img} alt="blba" className="w-[150px] xl:w-[200px]" />
						</div>
						<div
							className={`flex flex-col h-full xl:h-auto items-start rounded-tr-[6rem] rounded-tl-[1.6rem] rounded-b-[1rem] pl-6 gap-4 pt-6 pb-[2.5rem] relative z-0`}
							style={{ backgroundColor: c.color1 }}
						>
							<div
								className="absolute w-full h-[7.5rem] bottom-0 left-0 rounded-t-[12rem] rounded-b-[1rem] z-10"
								style={{ backgroundColor: c.color2 }}
							></div>
							<div className="pr-[4rem]">
								<h3 className="font-sansita_one sm:text-[1.8rem] text-[2rem] lg:text-[2.5rem]">
									{c.title}
								</h3>
							</div>
							<div className="pr-12 z-20">
								<p className="sm:text-[1.2rem] text-[1.25rem] font-semibold text-cinza2 xl:text-[2rem]">
									{c.description}
								</p>
							</div>
						</div>
					</div>
				))}
			</div>

			<img
				src={fundo2}
				alt="fundo2"
				className="absolute sm:top-[600px] sm:-left-[200px] top-36 -left-[720px] lg:-left-[450px] md:-left-[390px] lg:top-[400px] md:top-[400px] lg:scale-75 z-[1]"
			/>
		</div>
	);
};

export default Cultura;
