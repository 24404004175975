import React from 'react'
import tenda from '../assets/images/Tenda.jpg'
import sonda from '../assets/images/Sonda.jpg'
import amigao from '../assets/images/amigao.jpg';
import jau from '../assets/images/jau.jpg';
import benassi from '../assets/images/Benassi.jpg';
import demarchi from '../assets/images/DeMarchi.jpg';
import benafrutti from '../assets/images/Benafrutti.jpg';
import frutamina from '../assets/images/Frutamina.jpg';

const Clientes = () => {
    return (
        <div id="clientes" className='flex flex-col w-full gap-16 md:gap-0 py-14'>
            <h2 className="text-[3rem] font-bold text-center md:text-[2.3rem] xl:text-[4rem] md:mb-16">
                Nossos{" "}
                <span className="text-verde-escuro2">clientes</span>
            </h2>
            <div className="grid grid-cols-1 gap-10 items-center">
                <div className="grid grid-cols-4 md:grid-cols-2 place-items-center gap-10 md:gap-0">
                    <img src={tenda} alt="Tenda" className='md:w-[100px]' />
                    <img src={sonda} alt="Sonda" className='md:w-[100px]' />
                    <img src={amigao} alt="Amigão" className='md:w-[100px]' />
                    <img src={jau} alt="Jaú Serve" className='md:w-[100px]' />
                </div>
                <div className="grid grid-cols-4 md:grid-cols-2 place-items-center">
                    <img src={benassi} alt="Benassi" className='md:w-[100px]' />
                    <img src={demarchi} alt="DeMarchi" className='md:w-[100px]' />
                    <img src={benafrutti} alt="Benafrutti" className='md:w-[100px]' />
                    <img src={frutamina} alt="Frutamina" className='md:w-[100px]' />
                </div>
            </div>
        </div>
    )
}

export default Clientes