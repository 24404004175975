import hero from "../assets/images/farm2.jpg"

function Hero() {
  return (
    <div id="hero" className="relative w-full h-screen drop-shadow-xl z-[4]">
      <img 
        src={hero} 
        className="w-full h-full object-cover" 
        alt="Hero"
        loading="eager"
      />
      <div className="absolute inset-0 bg-black/20" />
      <div className="absolute inset-0 flex flex-col items-center justify-end -translate-y-10 text-white text-center">
        {/* <h1 className="sm:text-6xl text-8xl font-sansita_one">
          Orgânico,<br />
          Sustentável,<br />
          E Fresco.
        </h1> */}
        <h2 className="sm:text-base sm:px-10 text-xl font-medium max-w-[43.75rem] pb-[10rem]">
        Comprometidos com a qualidade e o meio ambiente, comercializamos alimentos
que respeitam a natureza e nutrem a vida. Nosso beneficiamento garante 
produtos frescos e selecionados. Cultivados de forma sustentável, preservando o solo e os
recursos naturais para as futuras gerações. Do Campo à sua mesa, levamos
saúde e sabor com responsabilidade ambiental.
        </h2>
      </div>
    </div>
  )
}

export default Hero