import detalhe from "../assets/images/back_filiais.svg"
import whatsapp2 from "../assets/images/whatsapp.png"

interface FilialCardProps {
  loja: {
    cidade: string;
    funcao: string;
    texto: string;
    endereco: string;
    enderecoGPS: string;
    imagem: string;
    whatsapp: string;
    email: string;
  }
}

const FilialCard = ({ loja: { cidade, funcao, texto, endereco, enderecoGPS, imagem, whatsapp, email} }: FilialCardProps) => {

  const openWhatsapp = () => {
    window.open(`https://api.whatsapp.com/send?phone=${whatsapp}`, '_blank');
  }

  return (
    <div className='md:w-full w-3/4 xl:w-[90%] bg-cinza-claro flex md:flex-col items-center rounded-2xl justify-center gap-5 sm:p-3 p-5 z-[9]'>
      <div className='md:w-full w-[55%] md:h-[550px] h-[440px] xl:h-auto bg-verde-escuro4 rounded-2xl p-5 relative'>
        <div className="absolute inset-0 z-[1] overflow-hidden rounded-2xl">
          <img 
            src={detalhe} 
            alt="detalhe de fundo" 
            className="w-full h-full object-cover"
          />
        </div>

        <div className="flex flex-col relative z-[2]">
          <h1 className='font-sansita_one text-4xl text-amarelo-esverdeado'>{cidade}</h1>
          <h2 className='font-sansita_one text-2xl text-white'>{funcao}</h2>
          <h3 className='font-sansita_one text-xl text-gray-200 pt-4'>Horário de Funcionamento</h3>
          <div className='mt-0 text-lg text-white mb-4 font-light'>
            {texto.split(';').map((t, i) => (
              <p key={i}>{t}</p>
            ))}
          </div>
          <h3 className='font-sansita_one text-xl text-gray-200 pt-2'>E-mail</h3>
          <a href={`mailto:${email}`} className='mt-0 text-lg text-white mb-4 font-light underline'>{email}</a>
          <h3 className='font-sansita_one text-xl text-gray-200 pt-4'>Endereço</h3>
          <a href={enderecoGPS} className='mt-0 text-lg text-white mb-4 font-light underline'>{endereco}</a>
          
        </div>
      </div>

      <div className='md:w-full w-[45%] h-[440px] relative bg-verde-escuro4 overflow-hidden rounded-2xl'>
        <img src={imagem} alt="berti" className="object-cover w-full h-full " />
        <img onClick={openWhatsapp} src={whatsapp2} className="cursor-pointer absolute scale-75 sm:-bottom-12 sm:-right-12 -bottom-11 -right-11" alt="Clique aqui whatsapp" />
      </div>
    </div>
  )
}

export default FilialCard