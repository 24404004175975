import React, { useState } from "react";
import Cultura from "./Components/Cultura";
import Hero from "./Components/Hero";
import Sobre from "./Components/Sobre";
import logo from "./assets/images/logo.png";
import logo_footer from "./assets/images/logo-footer.png";
import Produtos from "./Components/Produtos";
import Filiais from "./Components/Filiais";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-scroll";
import forest from "./assets/images/forest.svg";
import Clientes from "./Components/Clientes";

function App() {
	const isMobile = useMediaQuery({ maxWidth: 850 });
	const [isMenuMobileOpen, setIsMenuMobileOpen] = useState<boolean>(false);

	return (
		<div className="min-h-screen flex flex-col font-public  overflow-hidden">
			<header className="w-full bg-white shadow-md fixed z-50">
				<div className="max-w-[1440px] flex justify-between items-center mx-auto px-4 py-4 font-semibold">
					<Link to="hero" spy={true} smooth={true} offset={-100} duration={500}>
						<img src={logo} className="w-36" alt="Berti Hortifrutti" />
					</Link>
					{isMobile ? (
						<div
							onClick={() => setIsMenuMobileOpen(!isMenuMobileOpen)}
							className="flex flex-col gap-[6px] cursor-pointer"
						>
							<div
								className={`w-7 h-1 bg-gray-500 ${isMenuMobileOpen ? "rotate-45  -mb-[4px]" : "rotate-0"
									} transition-all duration-200`}
							/>
							<div
								className={`w-7 h-1 bg-gray-500 ${isMenuMobileOpen ? "-rotate-45 -mt-[5.5px]" : "rotate-0"
									} transition-all duration-200`}
							/>
							<div
								className={`w-7 h-1 bg-gray-500 ${isMenuMobileOpen ? "hidden" : "block"
									} transition-all duration-200`}
							/>
						</div>
					) : (
						<>
							<div className="flex gap-16 text-black">
								<Link
									to="sobre"
									spy={true}
									smooth={true}
									offset={-100}
									duration={500}
									activeClass="text-[#13613D] border-b-2 border-b-[#13613D]"
								>
									{" "}
									<button className="text-lg border-b-2 border-b-transparent">
										Sobre Nós
									</button>
								</Link>
								<Link
									to="cultura"
									spy={true}
									smooth={true}
									offset={-100}
									duration={500}
									activeClass="text-[#13613D] border-b-2 border-b-[#13613D]"
								>
									{" "}
									<button className="text-lg border-b-2 border-b-transparent">
										Cultura
									</button>
								</Link>
								<Link
									to="produtos"
									spy={true}
									smooth={true}
									offset={-100}
									duration={500}
									activeClass="text-[#13613D] border-b-2 border-b-[#13613D]"
								>
									{" "}
									<button className="text-lg border-b-2 border-b-transparent">
										Produtos
									</button>
								</Link>
								<Link
									to="clientes"
									spy={true}
									smooth={true}
									offset={-100}
									duration={500}
									activeClass="text-[#13613D] border-b-2 border-b-[#13613D]"
								>
									{" "}
									<button className="text-lg border-b-2 border-b-transparent">
										Nossos Clientes
									</button>
								</Link>
							</div>
							<div className="flex gap-3">
								<Link
									to="locais"
									spy={true}
									smooth={true}
									offset={-100}
									duration={500}
								>
									<button className="bg-verde-claro text-white px-4 py-2 rounded-full text-lg font-bold relative overflow-hidden">
										<img
											src={forest}
											className="absolute inset-0 -top-[3px] left-1"
											alt="folhas"
										/>
										<img
											src={forest}
											className="absolute rotate-180 inset-0 top-[18px] -left-1"
											alt="folhas"
										/>
										Entrar em contato
									</button>
								</Link>
								<a href="/login" className="bg-verde-claro text-white px-4 py-2 rounded-full text-lg font-bold relative overflow-hidden">
									Login
								</a>
							</div>
						</>
					)}
				</div>

				{isMobile && (
					<div
						className={`fixed right-0 top-20 w-64 bg-white shadow-lg z-40 transform transition-transform duration-300 text-black ${isMenuMobileOpen ? "translate-x-0" : "translate-x-full"
							}`}
					>
						<div className="flex flex-col py-4">
							<div className="w-full flex flex-col items-center">
								<Link
									to="sobre"
									spy={true}
									smooth={true}
									offset={-100}
									duration={500}
									activeClass="text-[#13613D]"
								>
									<button className="text-lg py-3 px-6 text-left hover:bg-gray-100">
										Sobre Nós
									</button>
								</Link>
								<Link
									to="cultura"
									spy={true}
									smooth={true}
									offset={-100}
									duration={500}
									activeClass="text-[#13613D]"
								>
									<button className="text-lg py-3 px-6 text-left hover:bg-gray-100">
										Cultura
									</button>
								</Link>
								<Link
									to="produtos"
									spy={true}
									smooth={true}
									offset={-100}
									duration={500}
									activeClass="text-[#13613D]"
								>
									<button className="text-lg py-3 px-6 text-left hover:bg-gray-100">
										Produtos
									</button>
								</Link>
								<Link
									to="clientes"
									spy={true}
									smooth={true}
									offset={-100}
									duration={500}
									activeClass="text-[#13613D]"
								>
									<button className="text-lg py-3 px-6 text-left hover:bg-gray-100">
										Nossos Clientes
									</button>
								</Link>
							</div>
							<Link
								to="locais"
								spy={true}
								smooth={true}
								offset={-100}
								duration={500}
							>
								<button className="bg-verde-claro text-white mx-6 mt-4 py-2 rounded-full text-lg relative overflow-hidden font-bold">
									<img
										src={forest}
										className="absolute inset-0 -top-[3px] left-3"
										alt="folhas"
									/>
									<img
										src={forest}
										className="absolute rotate-180 inset-0 top-[18px]"
										alt="folhas"
									/>
									Entrar em contato
								</button>
							</Link>
							<a href="/login">
								<button className="bg-verde-claro w-[155px] text-white mx-6 mt-4 py-2 rounded-full text-lg relative overflow-hidden font-bold">
									Login
								</button>
							</a>
						</div>
					</div>
				)}
			</header>

			{/* Área principal para componentes - Adicionando padding-top para compensar o header fixo */}
			<main className="flex-grow ">
				<Hero />
				<div className="w-full max-w-[1440px] mx-auto px-4 py-8">
					<Sobre />
					<Cultura />
					<Produtos />
					<Clientes />
					<Filiais />
				</div>
			</main>

			{/* Footer */}
			<footer id="contato" className="w-[100vw]">
				<div className="flex text-white bg-verde-escuro3 mx-auto px-20 py-6 justify-between md:flex-col md:gap-3">
					<div className="flex flex-col font-public text-[0.875rem] ">
						<img
							src={logo_footer}
							alt="logo-berti"
							width={200}
							className="h-20 md:h-auto mb-8"
						/>
						{/* <ul className="space-y-1">
							<li>
								<a href="">Termos de contrato</a>
							</li>
							<li>
								<a href="">Termos de uso</a>
							</li>
							<li>
								<a href="">Documentos alimentos</a>
							</li>
							<li>
								<a href="">Página da matriz</a>
							</li>
							<li>
								Images by{" "}
								<a
									className="underline"
									href="https://www.freepik.com"
									target="_blank"
									rel="noreferrer"
								>
									FreePik
								</a>
							</li>
						</ul> */}
					</div>

					<div className="flex flex-col ">
						<h3 className="font-public text-[1.5rem] font-bold mb-4 after:block after:w-[140px] after:h-[5px] after:bg-white">
							Telefones Fixos
						</h3>
						<ul className="space-y-2 font-public text-[0.875rem]">
							<li>
								(19) 3608-7809
							</li>
							<li>
								(19) 99470-7888
							</li>
						</ul>
					</div>

					<div className="flex flex-col">
						<h3 className="font-public text-[1.5rem] font-bold mb-4  after:content-[''] after:block after:w-[180px] after:h-[5px] after:bg-white">
							Contato Comercial
						</h3>
						<ul className="space-y-2 font-public text-[0.875rem]">
							<li>
								São José do Rio Pardo: (19) 99494-3751 <br />{" "}
								<span className="text-[0.75rem]">Leandro</span>
							</li>
							<li>
								São José do Rio Preto: (19) 99231-2038 <br />{" "}
								<span className="text-[0.75rem]">Fernando</span>
							</li>
							<li>
								São Paulo: (11) 94176-3992 <br />{" "}
								<span className="text-[0.75rem]">Adevaldo</span>
							</li>
						</ul>
					</div>
					<div className="flex flex-col">
						<ul className="space-y-2 font-public text-[0.875rem]">
							<li>
								Campo Grande: (67) 99627-8396 <br />{" "}
								<span className="text-[0.75rem]">Paulo Renato</span>{" "}
							</li>
							<li>
								Ribeirão Preto: (19) 99415-1876 <br />{" "}
								<span className="text-[0.75rem]">Tiago</span>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex flex-col h-[44px] py-4 bg-verde-claro2 justify-center items-center">
					<p className="font-sansita_one text-[0.938rem]">
						Desenvolvido por Z2 Solutions
					</p>
				</div>
			</footer>
		</div>
	);
}

export default App;
