import { Link } from "react-scroll";
import assalariado from "../assets/images/assalariado.png";
import fundo1 from "../assets/images/fundo1.svg";

const Sobre = () => {
	return (
		<div
			id="sobre"
			className="w-full flex font-public gap-[8rem] pt-20 md:gap-[3rem] md:pt-8 md:flex-col xl:gap-[3rem] xl:pt-8 xl:flex-col relative"
		>
			<div className="flex flex-col justify-between w-[50%] md:w-full md:gap-6 xl:w-full xl:gap-6 relative  z-[2]">
				<div className="flex flex-col gap-8 md:gap-4 md:text-center xl:gap-4 xl:text-center">
					<div className="flex flex-col text-[2.5rem] font-bold items-start md:items-center md:text-[2rem] xl:items-center xl:text-[3rem]">
						<h2>
							Entrega de <span className="text-verde-escuro2">legumes</span>{" "}
							<br />
							Direto da fazenda para sua casa
						</h2>
					</div>
					<div className="flex flex-col text-cinza font-bold gap-3 items-start md:items-center md:text-[1rem] xl:items-center xl:text-[1.5rem]">
						<p>
							Ofereça aos seus clientes a qualidade e o frescor que eles merecem
							com nossos legumes colhidos diretamente da fazenda. Garantimos
							produtos frescos e selecionados, prontos para atender às demandas
							do seu negócio, sempre com um rigoroso controle de qualidade.
						</p>
						<p>
							Nossa cadeia de produção é otimizada para assegurar que os legumes
							cheguem ao seu estabelecimento no ponto ideal de consumo,
							preservando o sabor, a textura e os nutrientes. Com uma logística
							eficiente e uma parceria sólida com produtores locais, garantimos
							entregas pontuais e produtos da mais alta qualidade.
						</p>
					</div>
				</div>
				<div className="flex justify-start md:justify-center xl:justify-center">
					<Link
						to="produtos"
						spy={true}
						smooth={true}
						offset={-100}
						duration={500}
						activeClass="text-[#13613D] border-b-2 border-b-[#13613D]"
					>
						{" "}
						<button className="bg-verde-claro font-bold text-[1.25rem] text-white px-6 py-[0.65rem] rounded-[3.2rem] hover:bg-verde-escuro3 transition xl:text-[2rem] sm:text-[1.2rem]">
							Veja nossos produtos
						</button>
					</Link>
				</div>
			</div>
			<div className="w-[50%] md:w-full xl:w-full">
				<img src={assalariado} alt="Assalariado" className="relative  z-[2]" />
			</div>
			<img
				src={fundo1}
				alt="fundo1"
				className="absolute sm:-top-[60px] sm:-right-52 lg:-right-[650px] -top-36 -right-[720px] z-[1] md:-right-[500px]"
			/>
		</div>
	);
};

export default Sobre;
